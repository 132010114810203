@import "./color.css";


body {
  font-family: "Roboto", "Noto Sans JP", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "Meiryo UI", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", serif;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.app {
  overflow-y: auto;
  background: var(--background);
  flex: 1;
  padding-top: 5px;
  display: block;

}

.appContainer {
  margin-left: 5%;
  width: 90%;
}

.fORvkZ,
.bMpkeO,
.frXJJo,
.gMeWWG {
  height: initial !important;
  margin-top: 20px;
}

/* -----------Scroll---------- */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: var(--white);
  border: none;
  box-shadow: inset 0 0 2px #777;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  box-shadow: none;
}

.ant-form-explain {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* -----------Loading---------- */
.ant-spin-lg .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
}


/* -----------ボタン---------- */
.ant-btn {
  background-color: var(--primary);
  border: var(--white);
  color: var(--white);
  box-shadow: var(--btnShadow);
}


.ant-btn:hover {
  background-color: var(--blueColor);
  color: var(--white);
  border-color: var(--blueColor);
}

.ant-btn[disabled] {
  box-shadow: var(--btnShadow);
  background: #ebe9e9;
  border-color: #ebe9e9;
  color: var(--grayColor);
}

.ant-btn[disabled]:hover {
  box-shadow: var(--btnShadow);
  background: #ebe9e9;
  border-color: #ebe9e9;
  color: var(--grayColor);
}

/* -----------ボタン---------- */