@import "../../../color.css";

/* ---------MSG */
div.msgArea {
  margin-bottom: 10px;
  margin: auto;
}
.ant-alert-warning {
  background-color: var(--white);
  margin-bottom: 10px;
}
.ant-alert {
  padding: 8px 15px 8px 20px !important;
  font-size: 16px !important;
}
.ant-alert-banner {
  border-top: var(--orangeSolid);
  border-bottom: var(--orangeSolid);
  margin-bottom: 10px;
}
.ant-alert-message {
  color: var(--orange);
  font-weight: 600;
}
.exclamation {
  color: var(--orange);
  font-weight: 600;
}
.ant-alert-warning {
  background-color: var(--white);
}
.ant-alert-icon {
  position: absolute;
  top: 7px;
  right: 16px;
  left: initial;
}
.ant-alert-warning .ant-alert-icon {
  color: var(--blueColor);
  font-size: 25px;
  font-weight: 600;
}

/* ----------------------- */
