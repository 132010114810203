@import "../../../color.css";
.adminBadge {
  background: var(--orange);
  color: var(--white);
  line-height: 40px;
  width: 100px;
  text-align: center;
  height: 40px;
  font-size: 15px;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 5px;
}
