@import "../../color.css";

.commonTable {
  padding-top: 10px;
}

.commonTable .ant-table-thead  {
  background-color: #3c3744;
}
.commonTable .ant-table  {
  background-color: var(--white);
}

.commonTable .ant-table-thead > tr > th {
  color: var(--white);
  padding: 10px 10px 10px 10px;
  text-align: center;
}

.commonTable .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

.commonTable .ant-table-tbody > tr > td.right {
  text-align: right;
}

.commonTable .ant-table-tbody > tr > td.center {
  text-align: center;
}

.commonTable .ant-table-tbody > tr > td:first-child {
  border-left: var(--graySolid);
}

.commonTable .ant-table-tbody > tr > td:last-child {
  border-right: var(--graySolid);
}

.commonTable .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: var(--graySolid);
  border-bottom: var(--graySolid);
}

.commonTable .ant-table-body {
  border-bottom: var(--graySolid);
}

.commonTable .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer {
  margin-bottom: -17px !important;
}
