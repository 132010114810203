:root {
  --greenColor: #96be14 !important;
  --greenSolid: 1px solid #96be14 !important;
  --background: #f3f8f2 !important;
  --d9d9d9BorderNone: 0px solid #d9d9d9 !important;
  --e8e8e8BorderNone: 0px solid #e8e8e8 !important;
  --blueSolid: 1px solid #195fbe !important;
  --blueColor: #195fbe !important;
  --white: #fff !important;
  --orange: #e53d00 !important;
  --orangeSolid: 1.5px solid #e53d00 !important;
  --btnShadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --blackColor: #3c3744 !important;
  --blackSolid: 1px solid #3c3744 !important;
  --graySolid: 1px solid #757575 !important;
  --grayColor: #757575 !important;
  --primary: #1890ff !important
}
