@import "../../../color.css";
header {
  font-size: 16px;
  height: 80px;
  width: 100%;
  line-height: 80px;
  background: var(--white);
  text-align: left;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  box-shadow: 0 1px 0 rgba(255, 255, 255, 1);
}
.imgLogo {
  position: absolute;
  left: 50px;
}
header h1 {
  margin: auto;
  width: 100%;
  text-align: center;
}
.rightContentsgroup {
  display: flex;
  position: absolute;
  top: 0px;
  right: 30px;
  margin: 15px 0px;
}
.userInfo {
  text-align: right;
  line-height: 25px;
}
.userInfo div {
  height: 50%;
}
.menuContents {
  position: absolute;
  top: 3px;
  left: 10px;
  font-size: 30px;
}

.mainNavBar {
  background-color: #96be14;
  height: 40px;
}

.mainNavBarRightMenu {
  text-align: right;
  line-height: 40px;
}

.agreementLink {
  font-size: 16px;
  color: white;
  margin-right: 30px;
}
.agreementLink:hover {
  color: black;
}

.closeLink {
  font-size: 16px;
  color: white;
  border: 1px solid;
  border-color: white;
  border-radius: 5px;
  padding: 6px;
  margin: 3px;
}
.closeLink:hover {
  color: black;
}

.manualLink {
  font-size: 16px;
  color: white;
  border: 1px solid;
  border-color: white;
  border-radius: 5px;
  padding: 6px;
  margin: 2px;
}
.manualLink:hover {
  color: black;
}