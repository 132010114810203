@import "../../../color.css";

.ant-modal {
  height: 300px;
}
.modalIcon {
  padding-bottom: 20px;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 20px;
  word-break: keep-all;
}
.ant-modal-body {
  text-align: center;
  width: 100%;
  height: 100%;
}
/* .ant-modal-confirm .ant-modal-confirm-btns {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  float: initial;
}
.ant-modal-confirm-btns .ant-btn {
  background-color: var(--white);
  border: var(--blueSolid);
  color: var(--blueColor);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  width: 100px;
}

.ant-modal-confirm-btns .ant-btn:hover {
  background-color: var(--blueColor);
  color: var(--white);
} */
