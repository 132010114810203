.userContractInvoiceBtn {
    height: 25px;
    width: 40px;
    margin-right: 10px;
}

.downloadInvoiceBtn {
    height: 25px;
    width: 40px;
    margin-right: 10px;
}

.detailExecuteBtn {
    height: 25px;
}

.detailExecuteBtn_refunded {
    height: 25px;
    background-color: gray;
}

.popoverPre {
    white-space: pre-wrap;
    word-break: break-all;
    width: auto;
    max-width: 300px;
}

.ant-popover-inner-content {
    background-color: black;
    color: white;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    border-right-color: black;
    border-bottom-color: black;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    border-left-color: black;
    border-top-color: black;
}

.refundSelect {
    width: 100%;
}