@import "../invoiceList/invoiceList.css";

.Title h3 {
  margin-bottom: 10px;
}
.oneContent {
  width: 100% !important;
}

.contractStateNotify {
  font-size: 16px;
  color: #e53d00;
  margin-bottom: 10px;
  margin-top: 5px;
}

.oneContent#updateContract .ant-table-tbody > tr > td.left {
  text-align: left;
}
.oneContent#updateContract .ant-table-tbody > tr > td.right {
  text-align: right;
}
