@import "../../../color.css";

.sticky_area {
  position: relative;
  bottom: 10px;
  background: rgba(117, 117, 117, 0.7);
  height: 100px;
  display: flex;
  display: -ms-flexbox;
  width: 100%;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.totalPriceArea {
  width: calc(50% - 115px);
  right: 30px;
  top: 40px;
  justify-content: center;
  font-size: 18px;
  position: absolute;
  color: var(--white);
  display: flex;
  display: -ms-flexbox;
}
.totalPriceArea span.total,
.totalPriceArea span.total2 {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}

.totalPriceArea span.total2 {
  text-align: right;
}
.totalPriceArea span.total {
  text-align: left;
}

.ant-btn-group > .ant-btn.calcBtn:first-child:not(:last-child) {
  margin: auto;
}

.ant-btn.calcBtn {
  width: 150px;
  height: 50px;
}
