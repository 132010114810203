@import "../../../color.css";

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.serviceContents {
  position: relative;
}
.serviceContents .oneService {
  /* width: 90%; */
  margin: auto;
}

.serviceContents .nextCycle {
  font-size: 13px;
  /* color: black; */
  display: block;
  height: 20px;
}

.serviceContents .ant-card-bordered {
  border: var(--e8e8e8BorderNone);
  background: var(--white);
  position: relative;
}

.serviceContents .ant-card-head {
  background: var(--white);
  background: transparent;
  border-bottom: var(--e8e8e8BorderNone);
  border-radius: 2px 2px 0 0;
  min-height: 40px;
  height: 40px;
}

.serviceContents .ant-card-body {
  padding: 0px 0px 10px 50px !important;
  position: relative;
}
.serviceContents #updateBtn {
  top: 10px;
}

.serviceContents #renewBtn {
  top: 10px;
}

.serviceContents .contractStateNotify {
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
  margin-top: 5px;
}

.serviceContents .ant-collapse {
  background-color: var(--white);
  border: var(--d9d9d9BorderNone);
}
.serviceContents .ant-collapse-content {
  border-top: var(--d9d9d9BorderNone);
  width: 85%;
  margin-left: 40px;
}
.serviceContents .ant-collapse > .ant-collapse-item {
  border-bottom: var(--d9d9d9BorderNone);
}

.serviceContents .ant-table-thead > tr:first-child > th:first-child,
.serviceContents .ant-table-thead > tr:first-child > th:last-child {
  border-top-left-radius: initial;
  border-top-right-radius: initial;
}

.serviceContents .ant-table-tbody > tr > td:first-child {
  /* border-top: var(--graySolid); */
  /* border-bottom: var(--graySolid); */
  border-left: var(--graySolid);
  border-color: #d9d9d9;
  text-align: left;
}

.serviceContents .ant-table-tbody > tr > td.serviceName {
  border-left: var(--graySolid);
  border-color: #d9d9d9;
  text-align: left;
}

.serviceContents .ant-table-tbody > tr > td:last-child {
  /* border-top: var(--graySolid); */
  /* border-bottom: var(--graySolid); */
  border-right: var(--graySolid);
  border-color: #d9d9d9;
  width: 25%;
  text-align: right;
}

.serviceContents .ant-table-tbody > tr > td {
  padding: 1vw !important;
  text-align: center;
  border-bottom: var(--graySolid);
  border-color: #d9d9d9;
  /* font-size: 1vw; */
}

.serviceContents .ant-table-thead > tr > th {
  background: #3c3744;
  color: var(--white);
  padding: 10px 10px 10px 10px;
  text-align: center;
}

.serviceContents .ant-table-body {
  border-bottom: var(--graySolid);
  border-color: #d9d9d9;
}

.serviceContents .ant-table-body > tr > td.gray {
  background: #a9a9a9;
  display: block;
  line-height: 5px;
}

/* .serviceContents i, */
.serviceContents i.anticon.anticon-right.ant-collapse-arrow {
  color: var(--blueColor);
  font-size: 20px;
}

a#serviceContents {
  color: black;
}

/* tab */
/* Active Tab Class */
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #fff;
  background: #1890ff;
}

/* button */

.ant-btn.renewBtn {
  font-size: 16px;
  text-align: center;
  position: absolute;
  right: 0px;
  z-index: 2;
}

.ant-btn.changeBtn {
  font-size: 16px;
  text-align: center;
  position: absolute;
  right: 0px;
  z-index: 2;
  margin-top: 20px;
}

.cycleTab{
font-size: 16px;
}