.header-table .ant-table-tbody > tr > td.left {
    text-align: left;
}

.createBtn {
  margin: 0px 0px 0px 0px
}

.execFlag {
  width: 200px;
  text-align: left;
  margin:0 auto;
}