.searchBtn {
  margin: 0px 0px 0px 0px
}

.journalChildTable {
  border: none;
}

.journalChildTable .ant-table-thead .ant-table-column-title {
  color: #000;
  font-weight: bold;
}

.journalChildTable .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0px;
  width: 280px;
}

.journalChildTable .ant-table-thead > tr:first-child > th:last-child {
  border-top-left-radius: 0px;
}

.journalChildTable .ant-table-tbody > tr > td:first-child {
  border-left: none !important;
}

.journalChildTable .ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}

.journalChildTable .ant-table-bordered .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.journalChildTable .ant-table-body {
  border-bottom: none !important;
  margin-bottom: 1px;
}

.journalChildTable .ant-table-thead > tr > th.dateColumn {
  width: 180px;
}

.journalChildTable .ant-table-thead > tr > th.count {
  width: 100px;
}

.journalChildTable .ant-table-tbody > tr > td.count {
  text-align: right;
}
