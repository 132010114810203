@import "../../../color.css";

.ant-btn-group.applyBtnGroup {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
}

.applyBtn {
  /* background-color: var(--white);
  border-color: var(--blueColor); */
  width: 150px;
  height: 50px;
  /* color: var(--blueColor);
  box-shadow: var(--btnShadow); */
}
/* .applyBtn:hover {
  background-color: var(--blueColor);
  border-color: var(--blueColor);
  color: var(--white);
} */
/* .ant-btn[disabled] {
  box-shadow: var(--btnShadow);
  background: #ebe9e9;
  border-color: #ebe9e9;
  color: var(--blueColor);
}

.ant-btn[disabled]:hover {
  box-shadow: var(--btnShadow);
  background: #ebe9e9;
  border-color: #ebe9e9;
  color: var(--blueColor);
} */
