.download {
  text-align: center;
}
.downloadBtn {
  width: 150px;
}

.ant-btn.downloadBtn[disabled] {
  box-shadow: none !important;
}
  
.ant-btn.downloadBtn[disabled]:hover {
  box-shadow: none !important;
}

.ant-form-inline .ant-form-item > .ant-form-item-label  {
  display: block;
  line-height: 20px;
  text-align: left;
}
.ant-row.ant-form-item:last-child{
  top:20px
}
