@import "../../../color.css";

.invoiceList {
  position: relative;
}
.oneContent {
  width: 85%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 20px;
}
.oneContent .ant-table-thead > tr > th {
  background: #3c3744;
  color: var(--white);
}
.oneContent .ant-table-thead > tr {
  font-size: 16px;
}
.oneContent .ant-table-thead > tr:hover {
  background: var(--background);
}
.oneContent .ant-table-header.ant-table-hide-scrollbar {
  overflow: hidden !important;
  padding-bottom: 17px !important;
}

.oneContent .ant-table-thead > tr:first-child > th:first-child,
.oneContent .ant-table-thead > tr:first-child > th:last-child {
  border-top-left-radius: initial;
  border-top-right-radius: initial;
}

.oneContent .ant-table-tbody > tr > td:first-child {
  border-left: var(--graySolid);
}

.oneContent .ant-table-tbody > tr > td:last-child {
  border-right: var(--graySolid);
}

.oneContent .ant-table-tbody > tr > td {
  padding: 0.5vw !important;
  text-align: center;
  border-bottom: var(--graySolid);
}

.oneContent .ant-table-thead > tr > th {
  padding: 10px 10px 10px 10px;
  text-align: center;
}
.oneContent .ant-table-tbody > tr {
  background: var(--white);
}

.oneContent .ant-table-body {
  border-bottom: var(--graySolid);
}

a#invoiceList {
  color: black;
}

div.customerRemark {
  overflow: hidden;
  text-overflow: ellipsis;
}

.oneContent .ant-table-tbody > tr > td.customerRemark {
  white-space: nowrap;
  max-width: 0px;
}

.oneContent .ant-table-tbody > tr > td.left {
  text-align: left;
}

.oneContent .ant-table-tbody > tr > td.right {
  text-align: right;
}

.confirmInvoiceDeleteTable .ant-table-tbody > tr > td:last-child {
  border-right: var(--graySolid);
  border-color: #d9d9d9;

}
.confirmInvoiceDeleteTable .ant-table-tbody > tr > td.center {
  border-right: var(--graySolid);
  border-color: #d9d9d9;
  text-align: center;
}
.confirmInvoiceDeleteTable .ant-table-tbody > tr > td.right {
  border-right: var(--graySolid);
  border-color: #d9d9d9;
  text-align: right;
}

.popoverPre {
  font-family: "Roboto", "Noto Sans JP", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "Meiryo UI", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", serif;
}

.invoiceListPopover {
  width: 100% !important;
  max-width: 800px !important;
}