@import "../../color.css";

/* -------コンテンツ---------- */

.ant-col-22 {
  width: 100%;
  padding: 2vw 5vw 2vw 5vw;
  margin-bottom: 20px;
  border: var(--blackSolid);
  background: var(--white);
}
.list {
  margin-bottom: 50px;
}
/* -------コンテンツ---------- */
