.TitleAndDate {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
span.monthPickerMsg {
  font-size: 18px;
}
.TitleAndDate span:first-child {
  font-size: 18px;
}

.contractStateNotify {
  font-size: 16px;
  color: #e53d00;
  margin-bottom: 10px;
  margin-top: 5px;
}

.remainTerm {
  font-size: 18px;
  text-decoration: underline;
}