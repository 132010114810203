.refundBtn {
    margin: 100px 10px 0 30px;
}


.errMsg {
    color: #e53d00
}

.reconcileTable .ant-table-tbody > tr > td.right {
    text-align: right;
}

.ant-modal-confirm-title {
    text-align: left;
}

.summaryTable tr.ant-descriptions-row > td.companyId {
    width: 90px;
}

.summaryTable tr.ant-descriptions-row > td.companyName {
    min-width: 485px;
}

.summaryTable tr.ant-descriptions-row > td.companyType {
    min-width: 90px;
}

.summaryTable tr.ant-descriptions-row > td.chargingCycle {
    width: 200px;
}
