#picker {
  font-size: 16px;
  width: 110px;
  margin-left: auto;
}

.ant-calendar-month-panel-month {
  font-weight: 700;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month {
  font-weight: 100;
}

.contractUpdateMonthPicker .ant-input {
  border : 1px solid #9cd2ff;
  background-color: #1890ff;
  color: white;
}

.contractUpdateMonthPicker .ant-calendar-picker-icon {
  color: white;
}
