.loginContents {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 70vh;
}
.innerContents {
  width: 80%;
}
.btnGroup {
  display: flex;
  justify-content: space-evenly;
  padding-top: 30px;
  width: 100%;
}
.btnGroup button {
  width: 45%;
}

.dummyCompanyId {
  margin-left: 5px;
  width: 25%;
  margin-right: 20px;


}
.dummyManagerFlag {
  margin-left: 5px;
  width: 25%;
}