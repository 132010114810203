.customerRemarkTable {
  display: flex;
  position: relative;
  width: 100%;
  bottom: 10px;
}

.customerRemarkTable .ant-col-22 {
  margin-bottom: 10px;
}

.customerRemarkTable .ant-table-thead > tr > th {
  background: #3c3744;
  color: var(--white);
  padding: 10px 10px 10px 16px;
  text-align: left !important; 
}

textarea.customerRemarkForm {
  min-width: 650px;
}