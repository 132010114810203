@import "../../../color.css";
@import "../../user/invoiceList/invoiceList.css";
.search {
  text-align: center;
  height: 50px;
}
.searchBtn {
  width: 100px;
}

.ant-btn.searchBtn[disabled] {
  box-shadow: none !important;
}
  
.ant-btn.searchBtn[disabled]:hover {
  box-shadow: none !important;
}

.ant-form-inline .ant-form-item > .ant-form-item-label  {
  display: block;
  line-height: 20px;
  text-align: left;
}
.ant-row.ant-form-item:last-child{
  top:20px
}

.ant-input.ant-input-lg {
  width: 200px;
}
