@import "../../../color.css";

.updateContractTable .ant-table-thead > tr > th {
    background: #3c3744;
    color: var(--white);
    padding: 10px 10px 10px 10px;
    text-align: center;
}
  
.updateContractTable .ant-table-tbody > tr > td.right {
    text-align: right;
}
  
.updateContractTable .ant-table-tbody > tr > td.center {
    text-align: center;
}

.ant-input-number-disabled,
.ant-input-number {
  width: 100%;
}
.ant-input-number-input {
  text-align: right !important;
  padding: 0px 30px 0px 0px !important;
}
.ant-input-number.ant-input-number-disabled.ant-input-number-focused {
  border-color: #d9d9d9;
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
}

.ant-switch {
  height: 32px !important;
  width: 80% !important;
  max-width: 70px;
  min-width: 60px;
}

.ant-switch::after {
  width: 24px !important;
  height: 24px !important;
  top: 3px;
}

.ant-switch-inner {
  font-size: 14px !important;
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  color: #fff;
  width: 100%;
  position: relative;
  text-align: right;
  padding-right: 6px;
}
.ant-switch-checked .ant-switch-inner {
  text-align: left;
  padding-right: 0px;
  padding-left: 6px;
}

.notice {
  text-align: left;
  color: var(--orange);
}

.gray {
  background: #a9a9a9;
  line-height: 5px;
  text-align: left;
  border: none;
}