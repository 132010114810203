.detail-table .ant-table-tbody > tr > td.right {
  text-align: right;
}

.detail-table .ant-table-tbody > tr > td.left {
  text-align: left;
}

.ant-table-tbody > tr > td.breakAll {
  word-break: break-all;
}
 
.backBtn {
  margin-top: 10px;
  width: 100px;
  height: 30px;
}
