@import "../../../color.css";

.invoiceDeleteTable{
    margin: auto;
    margin-left: 33%;
    margin-top: 20px;
    position: relative;
    width: 35%;
}

.invoiceDeleteTable .ant-table-thead > tr > th {
    background: #3c3744;
    color: var(--white);
    padding: 10px 10px 10px 10px;
    text-align: center;
}

.invoiceDeleteTable .ant-table-tbody > tr > td {
    background: #ffffff;
    border-bottom: var(--graySolid);
    border-color: #d9d9d9;
}

.invoiceDeleteTable .ant-table-tbody > tr > td:first-child {
    border-color: #d9d9d9;
}

.invoiceDeleteTable .ant-table-tbody > tr > td:last-child {
    border-right: var(--graySolid);
    border-color: #d9d9d9;

}.invoiceDeleteTable .ant-table-tbody > tr > td.date {
    border-right: var(--graySolid);
    border-color: #d9d9d9;
    text-align: center;
}

.invoiceDeleteTable .ant-table-thead > tr > th {
    background: #3c3744;
    color: var(--white);
    padding: 10px 10px 10px 10px;
    text-align: center;
}

.confirmInvoiceDeleteTable .ant-table-thead > tr > th {
    background: #3c3744;
    color: var(--white);
    padding: 10px 10px 10px 10px;
    text-align: center;
}

.confirmInvoiceDeleteTable .ant-table-tbody > tr > td {
    background: #ffffff;
    border-bottom: var(--graySolid);
    border-color: #d9d9d9;
}

.confirmInvoiceDeleteTable .ant-table-tbody > tr > td:first-child {
    border-color: #d9d9d9;
}

.confirmInvoiceDeleteTable .ant-table-tbody > tr > td:last-child {
    border-right: var(--graySolid);
    border-color: #d9d9d9;

}.confirmInvoiceDeleteTable .ant-table-tbody > tr > td.date {
    border-right: var(--graySolid);
    border-color: #d9d9d9;
    text-align: right;
}

.DeleteButton {
    margin-left: 49%;
    margin-top: 40px;
}

.notice-line {
    margin-left: 33%;
    position: relative;
    color: var(#000000);
    font-weight: bold;
}
