@import "../../../color.css";
div.additionalInput .inputRow {
  margin: 4px 0px;
}
div.additionalInput .inputRow .header {
  text-align: left;
  height: 32px;
  padding: 4px;
}
div.additionalInput .inputRow .header span.headerItem {
  vertical-align: middle;
}
